/* Container Styling */
.printer-types-container {
    width: 90%;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f8f9fa;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  /* Main Title */
  .title {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px solid #4CAF50;
    display: inline-block;
    padding-bottom: 5px;
  }
  
  /* Subtitle for Series */
  .subtitle {
    text-align: center;
    font-size: 2rem;
    color: #555;
    margin: 40px 0 20px;
    text-transform: capitalize;
    border-bottom: 1px solid #ccc;
    display: inline-block;
    padding-bottom: 3px;
  }
  
  /* Grid Layout */
  .printer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  /* Printer Card Styling */
  .printer-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .printer-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  /* Printer Image */
  .printer-image {
    width: 100%;
    height: 200px;
    object-fit: contain;
    margin-bottom: 15px;
  }
  
  /* Printer Name */
  .printer-name {
    font-size: 1.3rem;
    font-weight: bold;
    color: #222;
    margin-bottom: 10px;
  }
  
  /* Printer Description */
  .printer-description {
    font-size: 0.90rem;
    color: #666;
    margin-bottom: 15px;
    height: 80px;
    overflow: hidden;
  }
  
  /* Buy Now Button */
  .buy-now-button {
    padding: 10px 20px;
    background-color: #1E335B;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-decoration: none;
  }
  
  .buy-now-button:hover {
  background-color: #1E335B;
  transform: scale(1.05);
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .printer-image {
      height: 150px;
    }
  
    .printer-name {
      font-size: 1.1rem;
    }
  
    .printer-description {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 2rem;
    }
  
    .subtitle {
      font-size: 1.5rem;
    }
  
    .buy-now-button {
      width: 100%;
      padding: 10px 0;
    }
  }
  
  
  