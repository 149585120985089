.about-container {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
  }
  

  
  .hero-section .title {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .hero-section .title span {
    font-weight: bold;
  }
  
  .hero-section .intro-text {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .about-content,
  .services-section,
  .contact-section {
    margin: 20px 0;
  }
  
  .section-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
    border-bottom: 2px solid #007bff;
    display: inline-block;
    padding-bottom: 5px;
  }
  
  .description {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .services-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .service-item {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #f9f9f9;
  }
  
  .service-item h3 {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  
  .contact-text {
    font-size: 1rem;
    text-align: center;
    margin-top: 20px;
  }
  