.slide-item {
    height: 200px; /* Adjust the height of each slide as needed */
    background-color: #1E335B;
  }
  
  .carousel-content {
    text-align: center;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  
  .carousel-content h3 {
    font-size: 2.5rem; /* Adjust the font size of heading */
    margin-bottom: 20px;
  }
  
  .carousel-content p {
    font-size: 1.5rem; /* Adjust the font size of paragraph */
  }

  @media (max-width: 768px) {
    .carousel-content h3 {
        font-size: 1.5rem
    }
    .carousel-content p {
        font-size: 1rem; /* Adjust the font size of paragraph */
      }
  }
  