body {
  background-color: #F5F5F5;
  color: #333;
}
.navbar {
 
  background-color:#FFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.jumbotron {
  background-color: #1E335B;
  color: #FFF;
  padding: 100px 0;
}
.jumbotron h1 {
  font-size: 3.5rem;
}
.jumbotron p {
  font-size: 1.25rem;
}
.jumbotron .btn-primary {
  background-color: #F9A825;
  border-color: #F9A825;
}
.jumbotron .btn-primary:hover {
  background-color: #F57F17;
  border-color: #F57F17;
}
section {
  padding: 50px 0;
}
.section-header {
  margin-bottom: 20px;
}

.features
{
  display: grid;
  /* grid-template-columns: repeat(auto-fit,minmax(250px,3fr)); */
  grid-template-columns: repeat(3,1fr);
  justify-content: center;
  grid-gap:20px;
 
}

.feature-box {
  background-color: #FFF;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
 
  height: 100%;
}

.feature-box-2 {
  background-color: #1E335B;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
  margin: 8px;
  height: 100%;
}
.feature-box:hover {
  transform: translateY(-10px);
}
.feature-box-2:hover {
  transform: translateY(-10px);
}
.feature-icon {
  font-size: 3rem;
  color: #1E335B;
  margin-bottom: 20px;
}
.feature-box h2 {
  color: #1E335B;
  font-size: 1.5rem;
  margin-bottom: 20px;
}
.feature-box p {
  color: #666;
  text-align: center;
}
.call-to-action {
  background-color: #1E335B;
  color: #FFF;
  padding: 100px 0;
}
.call-to-action h2 {
  font-size: 3rem;
  margin-bottom: 40px;
}
.call-to-action p {
  font-size: 1.5rem;
  margin-bottom: 40px;
}
.footer {
  background-color: #1E335B;
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  padding: 0;
}



.about-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.about-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-container p {
  font-size: 1.2rem;
  line-height: 1.6;
}

.table-wrapper {
  max-height: 400px; /* Set a maximum height for the table wrapper */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Fix the table header */
.table-wrapper thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ffffff; /* Optional: Change background color of header */
}


@media screen and (max-width: 768px) {

  .features {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px)
{
  .features {
    grid-template-columns: repeat(1, 1fr);
  }
}